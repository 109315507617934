<template>
  <validation-observer
    ref="logEntryForm"
    #default="{invalid}"
  >
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <b-row class="match-height">
        <b-col
          cols="12"
        >
          <b-card>
            <b-card-body style="padding: 0rem;">
              <b-form
                v-if="false"
                @submit.prevent="submitForm"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in logQuestions"
                    :key="item.id"
                    cols="12"
                  >
                    <b-form-group
                      :label="`${index+1}. ${item.question.text}`"
                      :label-for="`question${index+1}`"
                    >
                      <validation-provider
                        v-if="item.question.input_type.name === 'text' && !item.question.text.includes('Patient Number')"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <b-form-input
                          :id="`question${index+1}`"
                          v-model="responses[index]"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :name="`question${index+1}`"
                          data-vv-validate-on="blur"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'text' && item.question.text.includes('Patient Number')"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <cleave
                          v-model="responses[index]"
                          :options="cleaveOptions"
                          class="form-control"
                          :name="`question${index+1}`"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :raw="false"
                          data-vv-validate-on="blur"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'numeric'"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <cleave
                          v-model="responses[index]"
                          :options="numericCleaveOptions"
                          class="form-control"
                          :name="`question${index+1}`"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :raw="false"
                          data-vv-validate-on="blur"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'datetime'"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <flat-pickr
                          v-model="responses[index]"
                          :config="{ dateFormat: 'Z', altInput: true, altFormat: 'F j, Y h:i K', enableTime: true }"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          class="form-control"
                          :name="`question${index+1}`"
                          data-vv-validate-on="blur"
                          @on-open="setMinimumDateTime"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'time'"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <flat-pickr
                          v-model="responses[index]"
                          :config="{ enableTime: true, noCalendar: true, dateFormat:'H:i:S',time_24hr: true, defaultHour: 0, minuteIncrement: 1, enableSeconds: true }"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          class="form-control"
                          :name="`question${index+1}`"
                          data-vv-validate-on="blur"
                          @on-open="setMinimumDateTime"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'multi-select-dropdown'"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <v-select
                          v-model="responses[index]"
                          multiple
                          :close-on-select="false"
                          :options="item.question.option_group.option_choices"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :name="`question${index+1}`"
                          data-vv-validate-on="blur"
                        >
                          <span slot="no-options">{{ $t('filter_no_data') }}</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'single-select'"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <v-select
                          v-model="responses[index]"
                          :options="item.question.option_group.option_choices"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :name="`question${index+1}`"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          data-vv-validate-on="blur"
                        >
                          <span slot="no-options">{{ $t('filter_no_data') }}</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'boolean'"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <v-select
                          v-model="responses[index]"
                          :options="booleanOptions"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :name="`question${index+1}`"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          data-vv-validate-on="blur"
                        >
                          <span slot="no-options">{{ $t('filter_no_data') }}</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'textarea'"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <b-form-textarea
                          v-model="responses[index]"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :name="`question${index+1}`"
                          rows="3"
                          max-rows="8"
                          data-vv-validate-on="blur"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <validation-provider
                        v-if="item.question.input_type.name === 'multi-select-modal' && filteredEntries.length >= 0"
                        #default="{ errors }"
                        :vid="`question${index+1}`"
                        :rules="{ required: item.required }"
                      >
                        <v-select
                          v-model="serviceResponses"
                          multiple
                          :disabled="filteredEntries.length === 0"
                          :close-on-select="false"
                          :options="filteredEntries"
                          :state="errors.length > 0 ? false:null"
                          :class="errors.length > 0 ? 'is-invalid':null"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :name="`question${index+1}`"
                          data-vv-validate-on="blur"
                        >
                          <span slot="no-options">{{ $t('filter_no_data') }}</span>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                      <b-alert
                        v-if="item.question.input_type.name === 'multi-select-modal' && serviceResponses.length > 0"
                        v-height-fade.appear
                        :show="true"
                        class="mt-1"
                        icon-pack="feather"
                        icon="icon-info"
                      >
                        <div
                          class="alert-body"
                        >
                          <feather-icon
                            icon="InfoIcon"
                            class="mr-50"
                          />
                          <span>If viewing on a mobile device, please be sure to scroll the table below to the right to enter the time spent on each service.</span>
                        </div>
                      </b-alert>

                      <b-table
                        v-if="item.question.input_type.name === 'multi-select-modal' && serviceResponses.length > 0"
                        :fields="fields"
                        :items="serviceResponses"
                        responsive
                        sticky-header
                        striped
                        bordered
                        class="mt-2"
                      >
                        <template #cell(name)="data">
                          {{ data.item.label }}
                        </template>
                        <template #cell(gold_standard_time)="data">
                          {{ data.item.gold_standard_time.time }} ({{ unitsOfTimeFormatted(data.item.gold_standard_time.time) }})
                        </template>
                        <template #cell(actual_time_spent)="data">
                          <validation-provider
                            #default="{ errors }"
                            :vid="`actualTime${data.index}`"
                            :rules="{ required: item.required }"
                          >
                            <flat-pickr
                              v-model="serviceResponses[data.index].answer_time"
                              :config="{ enableTime: true, noCalendar: true, dateFormat:'H:i:S', time_24hr: true, defaultHour: 0, minuteIncrement: 1, enableSeconds: true, disableMobile: true }"
                              :state="errors.length > 0 ? false:null"
                              :class="errors.length > 0 ? 'is-invalid':null"
                              class="form-control"
                              :name="`actualTime${data.index}`"
                              data-vv-validate-on="blur"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </template>
                        <template #cell(performance_indicator)="data">
                          <b-badge
                            v-if="serviceResponses[data.index].answer_time && serviceResponses[data.index].answer_time != '00:00:00'"
                            :variant="getPerformanceColour(getPerformancePercentage(data.item.gold_standard_time.time,serviceResponses[data.index].answer_time))"
                          >
                            {{ getPerformanceTitle(getPerformancePercentage(data.item.gold_standard_time.time,serviceResponses[data.index].answer_time)) }}
                          </b-badge>
                        </template>
                      </b-table>

                      <b-alert
                        v-if="item.question.input_type.name === 'multi-select-modal' && serviceResponses.length > 0"
                        v-height-fade.appear
                        :show="true"
                        class="mt-1"
                        icon-pack="feather"
                        icon="icon-info"
                      >
                        <div
                          class="alert-body"
                        >
                          <feather-icon
                            icon="InfoIcon"
                            class="mr-50"
                          />
                          <span>Click <a
                            href="https://storage.googleapis.com/dentyme-ddgi/resources/blood_pressure.pdf"
                            target="_blank"
                          ><b>here</b></a> to view guidelines about Hypertension in Adults</span>
                        </div>
                      </b-alert>
                      <b-alert
                        v-if="item.question.text==='Periodontal Type' && filteredEntries.length > 0"
                        v-height-fade.appear
                        :show="true"
                        class="mt-1"
                        icon-pack="feather"
                        icon="icon-info"
                      >
                        <div
                          class="alert-body"
                        >
                          <feather-icon
                            icon="InfoIcon"
                            class="mr-50"
                          />
                          <span>Click <a
                            href="https://storage.googleapis.com/dentyme-ddgi/resources/Staging%20and%20Grading%20Periodontitis.pdf"
                            target="_blank"
                          ><b>here</b></a> to learn about Staging and Grading Periodontis</span>
                        </div>

                      </b-alert>
                      <b-alert
                        v-if="item.question.text==='Periodontal Type' && filteredEntries.length > 0"
                        v-height-fade.appear
                        :show="true"
                        class="mt-1"
                        icon-pack="feather"
                        icon="icon-info"
                      >
                        <div
                          class="alert-body"
                        >
                          <feather-icon
                            icon="InfoIcon"
                            class="mr-50"
                          />
                          <span>Click <a
                            href="https://storage.googleapis.com/dentyme-ddgi/resources/periodontal_classification_scheme.pdf"
                            target="_blank"
                          ><b>here</b></a> to learn about a classification scheme for periodontal and peri‐implant diseases and conditions</span>
                        </div>
                      </b-alert>
                      <b-alert
                        v-if="item.question.text==='Periodontal Type' && filteredEntries.length > 0"
                        v-height-fade.appear
                        :show="true"
                        class="mt-1"
                        icon-pack="feather"
                        icon="icon-info"
                      >
                        <div
                          class="alert-body"
                        >
                          <feather-icon
                            icon="InfoIcon"
                            class="mr-50"
                          />
                          <span>Click <a
                            href="https://storage.googleapis.com/dentyme-ddgi/resources/periodontitis_chart.pdf"
                            target="_blank"
                          ><b>here</b></a> to view a chart of the stages of Periodontitis</span>
                        </div>

                      </b-alert>
                    </b-form-group>
                  </b-col>

                  <!-- totals -->
                  <b-col
                    v-if="serviceResponses.length > 0 && activeUser.currentRole.name === 'dental_hygienist'"
                    cols="12"
                  >
                    <b-alert
                      :show="true"
                      variant="info"
                      class="mb-50"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        <span>Total Gold Standard Time: <strong>{{ getTotalGoldStandardTime() }} ({{ unitsOfTimeFormatted(getTotalGoldStandardTime()) }})</strong></span>
                      </div>
                    </b-alert>
                  </b-col>

                  <!-- totals -->
                  <b-col
                    v-if="serviceResponses.length > 0 && activeUser.currentRole.name === 'dental_hygienist'"
                    cols="12"
                  >
                    <b-alert
                      :show="true"
                      variant="info"
                      class="mb-50"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        <span>Total Actual Time Spent: <strong>{{ getTotalActualTime() }} ({{ unitsOfTimeFormatted(getTotalActualTime()) }})</strong></span>
                      </div>
                    </b-alert>
                  </b-col>

                  <!-- submit warning -->
                  <b-col cols="12">
                    <b-alert
                      :show="true"
                      variant="warning"
                      class="mb-50"
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="InfoIcon"
                          class="mr-50"
                        />
                        <span>{{ $t('log_entries.edit_warning') }}</span>
                      </div>
                    </b-alert>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col
                    v-if="!preview"
                    cols="12"
                    class="mt-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                      :disabled="invalid || loading"
                    >
                      {{ $t('buttons.submit') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="resetForm"
                    >
                      {{ $t('buttons.reset') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <survey-sparrow
                v-else-if="surveyUrl"
                :office-id="activeUser.office_id"
                :user-id="activeUser.id"
                :user-name="activeUser.first_name"
                :loggable-item-history-id="loggableItemHistoryId"
                :iframe="true"
                :embed-token="embedToken"
                :url="surveyUrl"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BCard, BCardBody, BCol, BRow, BButton, BLink, BForm, BFormGroup, BFormInput, BOverlay, BFormTextarea, BTable, BBadge, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate'
import {
  required,
  regex,
} from '@validations'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import vSelect from 'vue-select'
import {
  heightFade,
} from '@core/directives/animations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

extend('required', {
  ...required,
  message: 'This field is required',
})

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    Cleave,
    flatPickr,
    vSelect,
    BFormTextarea,
    BTable,
    BBadge,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    SurveySparrow: () => import('@/views/components/surveysparrow/SurveySparrow.vue'),
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  props: {
    preview: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'gold_standard_time', label: 'Gold Standard Time' },
        { key: 'actual_time_spent', label: 'Actual Time Spent' },
        { key: 'performance_indicator', label: 'Performance Indicator' },
      ],
      loggableItemId: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      responses: [],
      serviceResponses: [],
      required,
      regex,
      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        prefix: 'Patient ',
        noImmediatePrefix: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
      },
      numericCleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
      },
      booleanOptions: [{ label: 'Yes', value: true }, { label: 'No', value: false }],
      sanitizedResponses: [],
      actualTimeSpent: [],
      saveMode: 'final',
      documentTitle: 'Log',
      documentDate: moment().format('l'),
      loggableItemHistoryId: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('loggableItems', ['dailyLogQuestions', 'loggableItem', 'goldStandardTimes', 'loggableItemPreview', 'surveyUrl', 'embedToken']),
    logQuestions() {
      if (this.preview && this.loggableItemPreview) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        const clone = JSON.parse(JSON.stringify(this.loggableItemPreview))
        const sorted = clone.question_orders.filter(a => a.visible).sort((a, b) => ((a.question_number > b.question_number) ? 1 : -1))
        return sorted
      }
      return this.dailyLogQuestions.filter(a => a.visible)
    },
    getDefaultDate() {
      return moment().format()
    },
    today() {
      return moment().startOf('day').format()
    },
    filteredEntries() {
      let entries = []
      const found = this.logQuestions.find(q => q.question.text === 'Periodontal Type') // find the question for periodontal type
      const perioOptionIds = found.question.option_group.option_choices.map(o => o.id) // retrieve option choice ids for all periodontal types
      if (found) {
        // const response = this.responsesStep1.find(o=>o.question_id === found.question_id);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.responses.length; i++) {
          if (this.responses[i] && perioOptionIds.indexOf(this.responses[i].value) > -1) {
            const perio = found.question.option_group.option_choices.find(o => o.id === this.responses[i].value)
            const serviceQuestion = this.step2.find(q => q.question.input_type.name === 'multi-select-modal')
            entries = serviceQuestion.question.option_group.option_choices.filter(o => o.gold_standard_time.periodontal_type_id === perio.periodontal_type_id)
            break
          }
        }
      }
      return entries
    },
    step1() {
      if (this.logQuestions.length > 0) {
        return this.logQuestions.filter(l => (l.question.option_group === null) || (l.question.option_group && l
          .question.option_group.name !== 'Services'))
      }
      return []
    },
    step2() {
      if (this.logQuestions.length > 0) {
        const clone = [...this.logQuestions]
        return clone.filter(l => l.question.option_group && l.question.option_group.name
            === 'Services')
      }
      return []
    },
  },
  watch: {
    responsesStep2: {
      deep: true,
      handler(val, oldVal) {
        if (oldVal[0].length > val[0].length) {
          const difference = oldVal[0].filter(x => !val[0].includes(x))
          const index = oldVal[0].indexOf(difference[0])
          // eslint-disable-next-line no-param-reassign
          delete oldVal[0][index].answer_time
          // this.actualTimeSpent.splice(index,1);
        }
      },
    },
  },
  async created() {
    try {
      this.loading = true
      await this.getDailyLogQuestions(this.activeUser.currentRole.id).then(res => {
        this.loggableItemId = res.data.id
      })
      const loggableItemHistory = {
        name: `${this.documentTitle} - ${this.documentDate}`,
        user_id: this.activeUser.id,
        loggable_item_id: this.loggableItemId,
      }
      await this.createLoggableItemHistory(loggableItemHistory).then(async res => {
        this.loggableItemHistoryId = res.data.id
        await this.setSurveyUrlAndToken({ survey_url: res.data.surveysparrow_unique_survey_url, embed_token: this.loggableItem.surveysparrow_embed_token })
      })
    } catch (e) {
      console.debug(`Error in created() of LogEntriesNew.vue: ${e.message}`)
    } finally {
      setTimeout(() => {
        this.loading = false
      }, 1500)
    }
  },
  methods: {
    ...mapActions('loggableItems', ['getDailyLogQuestions', 'getGoldStandardTimes', 'createLoggableItemHistory', 'createLoggableItemHistoryDraft', 'createResponse', 'deleteLoggableItemHistory', 'setSurveyUrlAndToken']),
    submitForm() {
      this.$refs.logEntryForm.validate().then(async success => {
        if (success) {
          try {
            this.loading = true
            this.sanitizedResponses = []
            this.sanitizeResponses(this.dailyLogQuestions, this.responses)
            this.sanitizeResponses(this.step2, this.serviceResponses)
            this.saveMode = 'final'
            const loggableItemHistory = {
              name: `${this.documentTitle} - ${this.documentDate}`,
              user_id: this.activeUser.id,
              loggable_item_id: this.loggableItemId,
              responses: this.sanitizedResponses,
              loggable_item_history_draft: { draft: JSON.stringify(this.sanitizedResponses) },
              submitted_at: moment(),
            }
            // if (this.saveMode === 'final') {
            //   loggableItemHistory.submitted_at = moment()
            // }
            await this.createLoggableItemHistory(loggableItemHistory)
            this.showSuccessToast('The log was successfully created')
            setTimeout(() => {
              if (this.activeUser.currentRole.name !== 'patient') {
                this.$router.push({ name: 'log-entries' }).catch(() => {})
              }
            }, 1000)
            // eslint-disable-next-line no-plusplus
            // for (let i = 0; i < this.sanitizedResponses.length; i++) {
            //   this.sanitizedResponses[i].loggable_item_history_id = response.data.id
            // }
            // if (this.saveMode === 'draft') {
            //   const payload = { draft: JSON.stringify(this.sanitizedResponses), loggable_item_history_id: response.data.id }
            //   try {
            //     await this.createLoggableItemHistoryDraft(payload)
            //     this.showSuccessToast('The log (draft) was successfully created')
            //     setTimeout(() => {
            //       this.$router.push({ name: 'log-entries' })
            //     }, 1000)
            //   } catch (e) {
            //     await this.deleteLoggableItemHistory(response.data.id)
            //     this.showErrorToast('Oops! Could not save draft. Please try again.')
            //   }
            // } else {
            //   const payload = { draft: JSON.stringify(this.sanitizedResponses), loggable_item_history_id: response.data.id }
            //   try {
            //     await this.createLoggableItemHistoryDraft(payload)
            //     await this.createResponse(this.sanitizedResponses)
            //     this.showSuccessToast('The log was successfully created')
            //     setTimeout(() => {
            //       this.$router.push({ name: 'log-entries' })
            //     }, 1000)
            //   } catch (e) {
            //     await this.deleteLoggableItemHistory(response.data.id)
            //     throw e
            //   }
            // }
          } catch (e) {
            console.debug(`Error in submitForm() in LogEntriesNew.vue: ${e.message}`)
            this.showErrorToast('Oops! Could not create appointment log. Please try again.')
          } finally {
            this.resetForm()
            setTimeout(() => {
              this.loading = false
            }, 1500)
          }
        }
      })
    },
    resetForm() {
      this.responses = []
      this.serviceResponses = []
      this.$refs.logEntryForm.reset()
    },
    setMinimumDateTime(selectedDates, dateStr, instance) {
      if (!dateStr) {
        instance.set('minDate', this.today)
        instance.setDate(this.getDefaultDate, true)
      }
    },
    getDropdown(objects) {
      const dropdown = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < objects.length; i++) {
        const obj = {}
        obj.label = obj.name || obj.description || 'Test'
        obj.value = obj.id
        dropdown.push(obj)
      }
      return dropdown
    },
    saveDraft() {
      this.sanitizedResponses = []
      this.sanitizeResponses(this.dailyLogQuestions, this.responses)
      this.sanitizeResponses(this.step2, this.serviceResponses)
      this.saveMode = 'draft'
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Confirm',
        text: 'Are you sure you would like to submit these entries as a draft?',
        accept: this.acceptAlert,
      })
    },
    getPerformancePercentage(goldStandardTime, actualTime) {
      const t1 = moment.duration(goldStandardTime).asSeconds()
      const t2 = moment.duration(actualTime).asSeconds()
      return (t2 / t1) * 100
    },
    getPerformanceColour(num) {
      if (num <= 100) return 'success'
      if (num > 100 && num <= 140) return 'primary'
      if (num > 140 && num <= 170) return 'warning'
      if (num > 170) return 'danger'
      return 'primary'
    },
    getPerformanceTitle(num) {
      if (num <= 100) return 'Excellent'
      if (num > 100 && num <= 140) return 'Good'
      if (num > 140 && num <= 170) return 'Satisfactory'
      if (num > 170) return 'Need to re-evaluate'
      return 'primary'
    },
    getTotalGoldStandardTime() {
      if (this.serviceResponses.length > 0) {
        const gst = this.serviceResponses.map(g => moment.duration(g.gold_standard_time.time).asSeconds())
        const sum = gst.reduce((a, b) => a + b, 0)
        return moment().startOf('day').seconds(sum).format('HH:mm:ss')
      }
      return null
    },
    getTotalActualTime() {
      if (this.serviceResponses.length > 0) {
        const ast = this.serviceResponses.map(a => moment.duration(a.answer_time).asSeconds())
        const sum = ast.reduce((a, b) => a + b, 0)
        return moment().startOf('day').seconds(sum).format('HH:mm:ss')
      }
      return null
    },
    unitsOfTime(timestamp) {
      const minutes = moment.duration(timestamp).asMinutes()
      const cursor = Math.floor(minutes / 15)
      let units = 0
      if (cursor === 0) {
        if (minutes <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      } else {
        units = cursor
        const diff = minutes - (15 * cursor)
        if (diff <= 7.5) {
          units += 0.5
        } else {
          units += 1
        }
      }
      return units
    },
    unitsOfTimeFormatted(timestamp) {
      const units = this.unitsOfTime(timestamp)
      if (units <= 1) {
        return `${units} unit`
      }

      return `${units} units`
    },
    showSuccessToast(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
          text: message,
        },
      }, 5000)
    },
    showErrorToast(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error',
          icon: 'XIcon',
          variant: 'danger',
          text: message,
        },
      }, 5000)
    },
    sanitizeResponses(questions, responses) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < questions.length; i++) {
        const obj = {}
        obj.question_id = questions[i].question_id
        if (responses[i]) {
          // eslint-disable-next-line default-case
          switch (questions[i].question.input_type.name) {
            case 'text':
              if (questions[i].question.text === 'Enter Patient Number') {
                this.documentTitle = responses[i]
              }
              obj.answer_text = responses[i]
              this.sanitizedResponses.push(obj)
              break
            case 'datetime':
              if (questions[i].question.text === 'Enter Appointment Scheduled Time') {
                this.documentDate = moment(responses[i]).format('l')
              }
              obj.answer_datetime = responses[i]
              this.sanitizedResponses.push(obj)
              break
            case 'time':
              obj.answer_time = responses[i]
              this.sanitizedResponses.push(obj)
              break
            case 'numeric':
              obj.answer_numeric = responses[i]
              this.sanitizedResponses.push(obj)
              break
            case 'single-select':
              obj.option_choice_id = responses[i].value
              this.sanitizedResponses.push(obj)
              break
            case 'multi-select-dropdown':
              if (Array.isArray(responses[i])) {
                // eslint-disable-next-line no-plusplus
                for (let k = 0; k < responses[i].length; k++) {
                  if (responses[i][k]) {
                    const obj2 = { ...obj }
                    obj2.option_choice_id = responses[i][k].value
                    if (responses[i][k].answer_time) {
                      obj2.answer_time = responses[i][k].answer_time
                    }
                    this.sanitizedResponses.push(obj2)
                  }
                }
              } else {
                obj.option_choice_id = responses[i].value
                if (responses[i].answer_time) {
                  obj.answer_time = responses[i].answer_time
                }
                this.sanitizedResponses.push(obj)
              }

              break
            case 'multi-select-modal':
              if (Array.isArray(responses)) {
                // eslint-disable-next-line no-plusplus
                for (let k = 0; k < responses.length; k++) {
                  if (responses[k]) {
                    const obj2 = { ...obj }
                    obj2.option_choice_id = responses[k].value
                    if (responses[k].answer_time) {
                      obj2.answer_time = responses[k].answer_time
                    }
                    this.sanitizedResponses.push(obj2)
                  } else {
                    obj.answer_time = responses[i]
                    this.sanitizedResponses.push(obj)
                  }
                }
              } else {
                obj.option_choice_id = responses[i].value
                if (responses[i].answer_time) {
                  obj.answer_time = responses[i].answer_time
                }
                this.sanitizedResponses.push(obj)
              }
              break
            case 'boolean':
              obj.answer_yn = responses[i].value
              this.sanitizedResponses.push(obj)
              break
            case 'textarea':
              obj.answer_text = responses[i]
              this.sanitizedResponses.push(obj)
              break
          }
        }
      }
    },
  },
  metaInfo() {
    return { title: this.$t('page_titles.new_log_entry'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
