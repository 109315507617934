<template>
  <b-card>
    <validation-observer
      ref="changeOrderForm"
    >
      <b-overlay
        :show="loading"
        :variant="overlayVariant"
        :opacity="overlayOpacity"
        rounded="sm"
      >
        <!-- form -->
        <b-form
          v-if="loggableItemPreview"
          @submit.prevent="saveChanges"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                v-for="item in loggableItemPreview.question_orders"
                :key="item.id"
                :label="item.question.text"
              >
                <b-form-spinbutton
                  min="1"
                  :value="item.question_number"
                  @input="updateQuestionNumber($event,item.question_id)"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                class="mt-1 mr-1"
              >
                {{ $t('buttons.save_changes') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mt-1 ml-25"
                variant="outline-secondary"
                @click.prevent="resetForm"
              >
                {{ $t('buttons.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAlert, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner, BFormRadioGroup, BOverlay, BFormCheckbox, BFormSpinbutton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  heightFade,
} from '@core/directives/animations'
import {
  required,
  regex,
} from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    ToastificationContent,
    vSelect,
    BOverlay,
    BFormCheckbox,
    BFormSpinbutton,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      required,
      regex,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('loggableItems', ['loggableItem', 'loggableItemPreview', 'questions', 'inputTypes', 'optionGroups']),
    questionsPreview() {
      if (this.loggableItem) {
        const loggableItemCopy = JSON.parse(JSON.stringify(this.loggableItem))
        const sorted = loggableItemCopy.question_orders.sort((a, b) => a.question_number - b.question_number)
        // eslint-disable-next-line no-unused-vars
        const [lastItem] = sorted.slice(-1)
        return sorted
      }
      return []
    },
  },
  watch: {
    newQuestion: {
      async handler(val) {
        await this.setExistingQuestion(val)
      },
      deep: true,
    },
  },
  async created() {
    await this.getQuestions()
    await this.getInputTypes()
    await this.getOptionGroups()
  },
  methods: {
    ...mapActions('user', ['updateUser']),
    ...mapActions('loggableItems', ['getQuestions', 'setLoggableItemPreview', 'createQuestionOrder', 'getInputTypes', 'getOptionGroups', 'createQuestion', 'getLoggableItem', 'bulkUpdateQuestionOrders']),
    async resetForm() {
      await this.setLoggableItemPreview(this.loggableItem)
      this.$refs.changeOrderForm.reset()
    },
    saveChanges() {
      this.$refs.changeOrderForm.validate().then(async success => {
        if (success) {
          this.serverError = null
          this.loading = true
          try {
            const changedOrders = []
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.loggableItemPreview.question_orders.length; i++) {
              const {
                question,
                created_at,
                updated_at,
                ...payload
              } = this.loggableItemPreview.question_orders[i]
              changedOrders.push(payload)
            }
            await this.bulkUpdateQuestionOrders(changedOrders)
            await this.getLoggableItem(this.loggableItem.id)
            this.resetForm()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Successfully updated question order',
              },
            })
          } catch (e) {
            console.debug(`Error in saveChanges() in AddQuestion.vue: ${e.message}`)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: 'Could not add question',
              },
            })
          } finally {
            this.loading = false
          }
        }
      })
    },
    async selectionChange() {
      await this.resetForm()
    },
    async setExistingQuestion(value) {
      if (this.loggableItem.question_orders.length > 0) {
        const { question_orders } = this.loggableItemPreview
        const clone = JSON.parse(JSON.stringify(this.loggableItemPreview)) // deep clone to avoid mutating on the vuex getter directly
        const [lastItem] = question_orders.slice(-1)
        if (value) {
          // increment question numbering
          const q = {
            id: lastItem.id + 1,
            required: true,
            visible: true,
            question_number: lastItem.question_number + 1,
            question: value,
          }
          if (this.loggableItem.question_orders.length !== this.loggableItemPreview.question_orders.length) {
            clone.question_orders.pop()
          }
          clone.question_orders.push(q)
          // update preview item
          await this.setLoggableItemPreview(clone)
        } else {
          clone.question_orders.pop()
          await this.setLoggableItemPreview(clone)
        }
      }
    },
    async updateQuestionNumber(e, id) {
      const clone = JSON.parse(JSON.stringify(this.loggableItemPreview))
      const index = clone.question_orders.findIndex(q => q.question_id === id)
      if (index !== -1) {
        clone.question_orders[index].question_number = e
        await this.setLoggableItemPreview(clone)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
