<template>
  <b-card
    v-if="loggableItemPreview"
  >
    <b-card-header>
      <b-card-title>{{ loggableItemPreview.name }} (Preview)</b-card-title>
      <!-- <b-card-text class="font-small-2">
        Last 30 days
      </b-card-text> -->
    </b-card-header>
    <b-card-body>
      <log-entries-new :preview="true" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import LogEntriesNew from '@/views/pages/log-entries/LogEntriesNew.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    LogEntriesNew,
  },
  props: {
    loggableItemId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('loggableItems', ['loggableItem', 'loggableItemPreview']),
  },
  async created() {
    await this.getLoggableItem(this.loggableItemId)
  },
  methods: {
    ...mapActions('loggableItems', ['getLoggableItem']),
  },
}
</script>
