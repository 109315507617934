<template>
  <b-card>
    <validation-observer
      ref="editQuestionForm"
    >
      <b-overlay
        :show="loading"
        :variant="overlayVariant"
        :opacity="overlayOpacity"
        rounded="sm"
      >
        <!-- form -->
        <b-form @submit.prevent="saveChanges">
          <b-row>
            <b-col
              cols="12"
            >
              <div>
                <b-form-group
                  label="Select question"
                  label-for="question-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Question"
                    vid="question-select"
                    rules="required"
                  >
                    <v-select
                      id="question-select"
                      v-model="existingQuestion"
                      :close-on-select="true"
                      name="role-select"
                      label="question_number"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      :options="questions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="existingQuestion"
                  label="Required (Required means question cannot be left empty)"
                  label-for="question-required"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Required"
                    vid="check-button"
                    rules="required"
                  >
                    <b-form-checkbox
                      v-model="existingQuestion.required"
                      name="check-button"
                      switch
                    >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-checkbox>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="existingQuestion"
                  label="Question Text"
                  label-for="question-text"
                >
                  <validation-provider
                    #default="{ errors }"
                    vid="question-text"
                    rules="required"
                  >
                    <b-form-input
                      id="question-text"
                      v-model="existingQuestion.question.text"
                      :state="errors.length > 0 ? false:null"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      name="question-text"
                      data-vv-validate-on="blur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="existingQuestion"
                  label="Select Input Type"
                  label-for="input-type-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Input Type"
                    vid="input-type-select"
                    rules="required"
                  >
                    <v-select
                      id="input-type-select"
                      v-model="existingQuestion.question.input_type"
                      :close-on-select="true"
                      :clearable="false"
                      name="input-type-select"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      :options="inputTypes"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  v-if="existingQuestion && existingQuestion.question.input_type && (existingQuestion.question.input_type.name === 'single-select' || existingQuestion.question.input_type.name === 'multi-select-dropdown' || existingQuestion.question.input_type.name === 'multi-select-modal')"
                  label="Select Option Group"
                  label-for="option-group-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Option Group"
                    vid="option-group-select"
                    rules="required"
                  >
                    <v-select
                      id="option-group-select"
                      v-model="existingQuestion.question.option_group"
                      :close-on-select="true"
                      :clearable="false"
                      name="option-group-select"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      :options="optionGroups"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>

              <hr>
              <b-alert
                :show="true"
                class="mt-1"
                icon-pack="feather"
                icon="icon-info"
              >
                <div
                  class="alert-body"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  <span>Input types represent the <em>type</em> of question presented to a user.</span>
                  <br><br>
                  <ul>
                    <li><b>Text</b>: This means that the user will be providing a text input to the question. This is
                      meant for short-answers.</li>
                    <li><b>Datetime</b>: This means that the user will be providing a date and time input to the
                      question. The user will see a calendar upon selection.</li>
                    <li><b>Time</b>: This means that the user will be providing a time input to the question. The user
                      will see a time box upon selection.</li>
                    <li><b>Single Select</b>: This means that the user will be selecting a single option from a
                      dropdown.</li>
                    <li><b>Multi-select Dropdown</b>: This means that the user will be selecting multiple options from a
                      dropdown.</li>
                    <li><b>Multi-select Modal</b>: Similar to above, but only meant for custom purposes. Avoid using for
                      now.</li>
                    <li><b>Text</b>: This means that the user will be providing a text input to the question. This is
                      meant for short-answers.</li>
                    <li><b>Boolean</b>: Boolean means truthiness. This means that the user will be selecting Yes or No
                      as their answer.</li>
                    <li><b>Textarea</b>: Similar to text, this means that the user will be providing a text input to the
                      question. However, this is meant for long answers. Ex: Notes/Comments</li>
                    <li><b>Numeric</b>: This means that the user will be providing a numeric input to the question. Ex:
                      Patient Number.</li>
                  </ul>
                </div>
              </b-alert>
              <b-alert
                :show="true"
                class="mt-1"
                icon-pack="feather"
                icon="icon-info"
              >
                <div
                  class="alert-body"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  <span>Option groups represent the content a user will see in a dropdown menu for questions with a
                    dropdown input type.</span>
                  <br><br>
                  <ul>
                    <li><b>Services</b>: This is a list of services that a RDH can render.</li>
                    <li><b>Obstacles</b>: This is the master list of obstacles.</li>
                    <li><b>Other Services</b>: This is the master list of other services that members talk to patients
                      about.</li>
                    <li><b>Periodontal Types</b>: This is the master list of all the periodontal types.</li>
                    <li><b>Recommended Toothbrushes</b>: This is the master list of all the toothbrush brands for
                      recommendation.</li>
                    <li><b>Agreement Levels</b>: This is a list that represents agreement. For example, strongly agree,
                      disagree, neither disagree or agree, etc.</li>
                    <li><b>Frequency Levels</b>: This is a list that represents frequency. For example, never, rarely,
                      always, etc.</li>
                    <li><b>Satisfaction Levels</b>: This is a list that represents satisfaction. For example, very
                      satisfied, satisfied, dissatisfied, etc.</li>
                  </ul>
                </div>
              </b-alert>
            </b-col>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                class="mt-1 mr-1"
              >
                {{ $t('buttons.save_changes') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mt-1 ml-25"
                variant="outline-secondary"
                @click.prevent="resetForm"
              >
                {{ $t('buttons.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAlert, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BSpinner, BFormRadioGroup, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  heightFade,
} from '@core/directives/animations'
import {
  required,
  regex,
  confirmed,
} from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  /* eslint-disable vue/no-unused-components */
  components: {
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    ToastificationContent,
    vSelect,
    BOverlay,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      serviceResponses: [],
      responses: [],
      newQuestion: {
        text: null,
        input_type: null,
        option_group: null,
      },
      existingQuestion: null,
      cleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        prefix: 'Patient ',
        noImmediatePrefix: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
      },
      numericCleaveOptions: {
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
      },
      addQuestionRadioChoice: 'existing',
      booleanOptions: [{
        label: 'Yes',
        value: true,
      }, {
        label: 'No',
        value: false,
      }],
      editedQuestions: [],
      required,
      regex,
      confirmed,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      radioOptions: [
        { text: 'Re-use an existing question from the database', value: 're-use' },
        { text: 'Add a new question', value: 'new' },
      ],
      selected: 're-use',
      requiredQuestion: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('loggableItems', ['loggableItem', 'loggableItemPreview', 'inputTypes', 'optionGroups']),
    questions() {
      if (this.loggableItem) {
        const loggableItemCopy = JSON.parse(JSON.stringify(this.loggableItem))
        const sorted = loggableItemCopy.question_orders.sort((a, b) => a.question_number - b.question_number)
        return sorted
      }
      return []
    },
  },
  watch: {
    existingQuestion: {
      async handler(val) {
        await this.setExistingQuestion(val)
      },
      deep: true,
    },
  },
  async created() {
    await this.getInputTypes()
    await this.getOptionGroups()
  },
  methods: {
    ...mapActions('user', ['updateUser']),
    ...mapActions('loggableItems', ['getQuestions', 'setLoggableItemPreview', 'createQuestionOrder', 'getInputTypes', 'getOptionGroups', 'createQuestion', 'getLoggableItem', 'updateQuestionOrder']),
    async resetForm() {
      await this.setLoggableItemPreview(this.loggableItem)
      this.existingQuestion = null
      this.$refs.editQuestionForm.reset()
    },
    saveChanges() {
      this.$refs.editQuestionForm.validate().then(async success => {
        if (success) {
          this.serverError = null
          this.loading = true
          try {
            await this.updateQuestionOrder(this.existingQuestion)
            await this.getLoggableItem(this.loggableItem.id)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Question edited successfully',
              },
            })
            this.resetForm()
          } catch (e) {
            console.debug(`Error in saveChanges() in AddQuestion.vue: ${e.message}`)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'XIcon',
                variant: 'danger',
                text: 'Could not edit question',
              },
            })
          } finally {
            this.loading = false
          }
        }
      })
    },
    async selectionChange() {
      await this.resetForm()
    },
    async setExistingQuestion(value) {
      try {
        if (this.loggableItemPreview.question_orders.length > 0) {
          const clone = JSON.parse(JSON.stringify(this.loggableItemPreview)) // deep clone to avoid mutating on the vuex getter directly
          const index = clone.question_orders.findIndex(q => q.id === this.existingQuestion.id)
          clone.question_orders[index] = this.existingQuestion
          if (value) {
            // update preview item
            await this.setLoggableItemPreview(clone)
          }
        }
      } catch (e) {
        console.debug(`Error in setExistingQuestion in EditQuestion.vue: ${e.message}`)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
