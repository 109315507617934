<template>
  <div>
    <b-row class="match-height">
      <b-col lg="6">
        <b-tabs pills>
          <!-- Add a Question tab -->
          <b-tab active>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Add a Question</span>
            </template>

            <add-question />
          </b-tab>
          <!--/ Add a question tab -->

          <!-- Change Question Order tab -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="RefreshCcwIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Change Question Order</span>
            </template>

            <change-question-order />
          </b-tab>
          <!--/ change question order tab -->

          <!-- Edit Question tab -->
          <b-tab>

            <!-- title -->
            <template #title>
              <feather-icon
                icon="EditIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Edit Question</span>
            </template>

            <edit-question />
          </b-tab>
          <!--/ Edit Question tab -->
        </b-tabs>
      </b-col>
      <b-col lg="6">
        <log-preview :loggable-item-id="$route.params.loggableItemId" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import AddQuestion from './AddQuestion.vue'
import ChangeQuestionOrder from './ChangeQuestionOrder.vue'
import EditQuestion from './EditQuestion.vue'
import LogPreview from './LogPreview.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    AddQuestion,
    ChangeQuestionOrder,
    EditQuestion,
    LogPreview,
  },
  data() {
    return {
      options: {},
      hideInformation: true,
      hideSocial: true,
      hideNotifications: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
  },
  // beforeCreate() {
  //   this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  // },
  metaInfo() {
    return { title: this.$t('page_titles.edit_log'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>
